<template>
	<el-dialog
		:visible.sync="visible"
		:show-close="false"
		:close-on-click-modal="false"
		:close-on-press-escape="false"
		class="role-dialog"
		width="350px">
		<span slot="title" class="dialog-title">
			<span>{{title}}</span>
			<img src="@/assets/images/close.png" alt="" @click="visible = false">
		</span>
		<el-form :model="dataForm" :rules="dataRule" label-position="top" ref="dataForm">
            <el-form-item label="机种分类">
                <el-select v-model="modelType" filterable placeholder="请选择" @change="setModel">
					<el-option
						v-for="item in deviceTypeList"
						:key="item.value"
						:label="item.name"
						:value="item.id">
					</el-option>
				</el-select>
            </el-form-item>
			<el-form-item label="机型分类" prop="deviceType">
                <el-select v-model="dataForm.deviceType" filterable placeholder="请选择">
					<el-option
						v-for="item in modelTypeList"
						:key="item.value"
						:label="item.name"
						:value="item.id">
					</el-option>
				</el-select>
            </el-form-item>
			<el-form-item label="机型分类名称" prop="name">
                <el-input v-model="dataForm.name" placeholder="机型分类名称" maxlength="65"></el-input>
            </el-form-item>
			<el-form-item label="机型分类说明" prop="remark">
                <el-input v-model="dataForm.remark" placeholder="机型分类说明" maxlength="256"></el-input>
            </el-form-item>
        </el-form>
		<span slot="footer" class="dialog-footer">
			<el-button class="submit-btn" @click="submit">确定</el-button>
		</span>
	</el-dialog>
</template>
<script>
import { debounce } from '@/utils/debounce'
export default {
	data() {
		return {
			title: '机型分类添加',
			visible: false,
			submitUrl: 'sysDataDeviceAdd',
			modelType: '',
			modelTypeList: [],
			deviceTypeList: [],
			dataForm: {
				deviceType: '',
				name: '',
				remark: ''
			},
			dataRule: {
				deviceType: [
					{required: true,message: '请选择机型分类', trigger: "change"}
				],
				name: [
					{required: true,message: '机型分类名称不能为空', trigger: "blur"}
				]
			}
		}
	},
	methods: {
		init(data,deviceTypeList) {
			this.visible = true;
			this.deviceTypeList = deviceTypeList;
			this.$nextTick(() => {
				this.$refs.dataForm.resetFields();
				this.modelType = '';
				if(data == 'add') {
					this.title = '机型名称添加';
					this.submitUrl = 'sysDataDeviceAdd';
					this.modelTypeList = [];
					delete this.dataForm.id;
				}else {
					this.title = '机型名称修改';
					this.dataForm.id = data.id;
					this.modelType = data.modelType;
					this.dataForm.name = data.name;
					this.dataForm.remark = data.remark;
					this.deviceTypeList.forEach(item => {
						if(item.id == data.modelType) {
							this.modelTypeList = item.children;
						}
					})
					this.dataForm.deviceType = data.deviceType;
					this.submitUrl = 'sysDataDeviceUpdate';
				}
			})
		},
		// 机型分类
		setModel(val) {
			this.dataForm.deviceType = '';
			this.deviceTypeList.forEach(item => {
				if(item.id == val) {
					this.modelTypeList = item.children;
				}
			})
		},
		submit:debounce(function() {
			if(this.visible == false) {
				return
			}
			this.$refs['dataForm'].validate(valid => {
				if (valid) {
					let that = this;
					that.$request.post(
						that.submitUrl,
						true,
						that.dataForm,
						function (r) {
							if (r.code == "0") {
								that.visible = false;
								that.$emit('getDataList');
								that.$message.success('保存成功');
							} else {
								that.$message.error(r.msg);
							}
						}
					);
				} else {
					return false;
				}
			});
		},1000)
	}
}
</script>
<style lang="scss" scoped>
.dialog-title{
	display: block;
	text-align: center;
	span{
		color: #222;
		font-weight: 600;
		font-size: 20px;
	}
	img{
		width: 28px;
		float: right;
		cursor: pointer;
	}
}
.el-form-item{
	margin-bottom: 10px;
	.el-select{
		width: 100%;
	}
}
.submit-btn{
	width: 100%;
	background-color: #117FFC;
	color: #fff;
}
.role-dialog /deep/ .el-dialog{
	border-radius: 10px !important;
}
.role-dialog /deep/ .el-dialog__body{
	padding: 0 20px !important;
}
.role-dialog /deep/ .el-form-item__label{
	padding: 0 !important;
}
.role-dialog /deep/ .el-dialog__footer{
	padding: 20px 20px 40px !important;
}
</style>
