<template>
	<div class="data-content">
		<div class="top-box">
			<div>
				<button class="add-btn" @click="handleEditData('add')" v-if="perms.includes('sys:device:add')">新增</button>
				<button class="import-btn" @click="handleImport" v-if="perms.includes('sys:device:import')">导入</button>
				<input style="display:none;" ref="uploadExcel" type="file" id="uploadExcel" @change="uploadHandle($event)">
				<button class="import-btn" @click="handleExport" v-if="perms.includes('sys:device:export')">导出</button>
				<button class="template-btn" @click="handleTemplate" v-if="perms.includes('sys:device:import')">模板下载</button>
			</div>
			<div class="top-right-box">
				<label class="label">关键字</label>
				<el-input class="search-input" size="small" type="text" v-model="searchKey" placeholder="请输入关键字"></el-input>
				<label class="label">机种分类</label>
				<el-select class="search-input" size="small" v-model="modelType" filterable placeholder="请选择" @change="setModel">
					<el-option value="" label="全部"></el-option>
					<el-option
						v-for="item in deviceTypeList"
						:key="item.value"
						:label="item.name"
						:value="item.id">
					</el-option>
				</el-select>
				<label class="label">机型分类</label>
				<el-select class="search-input" size="small" v-model="deviceType" filterable placeholder="请选择">
					<el-option value="" label="全部"></el-option>
					<el-option
						v-for="item in modelTypeList"
						:key="item.value"
						:label="item.name"
						:value="item.id">
					</el-option>
				</el-select>
				<img class="search-btn" src="@/assets/images/sousuo.png" alt="" @click="handleSearch">
				<el-button icon="el-icon-refresh-right" class="refresh-btn" @click="handleReset"></el-button>
			</div>
		</div>
		<div class="data-list-box">
			<el-table
				:data="dataList"
				:row-class-name="tableRowClassName"
				:header-cell-style="{color:'#444'}"
				style="width: 100%">
				<el-table-column
					label="NO."
					width="50">
					<template slot-scope="scope">
						<span class="no">{{scope.$index+1}}</span>
					</template>
				</el-table-column>
				<el-table-column
					prop="name"

					show-overflow-tooltip
					label="机型名称">
				</el-table-column>
				<el-table-column
					prop="modelName"
					width="130"
					show-overflow-tooltip
					label="机种分类">
				</el-table-column>
				<el-table-column
					prop="deviceName"
					width="130"
					show-overflow-tooltip
					label="机型分类">
				</el-table-column>
				<el-table-column
					prop="remark"
					width="200"
					show-overflow-tooltip
					label="机型名称说明">
				</el-table-column>
				<el-table-column
					label="操作"
					width="90">
					<template slot-scope="scope">
						<button class="btn-blue" @click="handleEditData(scope.row)" v-if="perms.includes('sys:device:update')">修改</button>
						<button class="btn-red" @click="handleDeleteData(scope.row)" v-if="perms.includes('sys:device:delete')">删除</button>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination-wrapper pa25">
				<Pagination
					:page.sync="pageNo"
					:pageSize.sync="pageSize"
					:total="total"
					@sizeChange="handleSizeChange"
					@currentChange="handleCurrentChange">
				</Pagination>
			</div>
		</div>
		<edit-data ref="editData" @getDataList="getDataList"></edit-data>
		<el-dialog
			:visible.sync="visible"
			:show-close="false"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			class="role-dialog"
			width="450px">
			<span slot="title" class="dialog-title">
				<span>提示</span>
				<img src="@/assets/images/close.png" alt="" @click="visible = false">
			</span>
			<ul class="error-list">
				<li v-for="(item,i) in errorList" :key="i">{{item}}</li>
			</ul>
		</el-dialog>
	</div>
</template>
<script>
import EditData from './components/edit-data'
import Pagination from '@/components/Pagination'
export default {
	components: {
		EditData,
		Pagination
	},
	data() {
		return {
			// 权限
			perms: JSON.parse(window.sessionStorage.getItem('perms')),
			page: 'page',
			searchKey: '',
			pageNo: 1,
			pageSize: 20,
			total: 0,
			dataList: [],
			deviceType: '',
			deviceTypeList: [],
			modelType: '',
			modelTypeList: [],
			visible: false,
			errorList: []
		}
	},
	created() {
		this.pageNo = 1;
		this.searchKey = '';
		// 获取机种机型分类数据
		this.getCodeData();
		// 获取机型名称数据
		this.getDataList();
	},
	methods: {
		// NO.隔行变色
		tableRowClassName({row,rowIndex}) {
			if(rowIndex%2 == 1) {
				return 'blue'
			}else {
				return 'yellow'
			}
		},
		// 机种机型分类
		getCodeData() {
			let that = this;
			that.$request.post(
				"sysGetDataDevice",
				true,
				{},
				function (r) {
					if (r.code == "0") {
						that.deviceTypeList = r.data;
					} else {
						that.$message.error(r.msg);
					}
				}
			);
		},
		// 设置机型分类
		setModel(val) {
			this.deviceType = '';
			if(val == '') {
				this.modelTypeList = [];
				return
			}
			this.deviceTypeList.forEach(item => {
				if(item.id == val) {
					this.modelTypeList = item.children;
				}
			})
		},
		// 新增/编辑/查看
		handleEditData(data) {
			this.$refs.editData.init(data,this.deviceTypeList);
		},
		// 删除
		handleDeleteData(data) {
			this.$confirm('是否删除当前机型?', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
			}).then(() => {
				let that = this;
				that.$request.post(
					'sysDataDeviceDelete',
					true,
					{
						id: data.id,
						delFlag: 1
					},
					function (r) {
						if (r.code == "0") {
							that.getDataList();
							that.$message.success('删除成功');
						} else {
							that.$message.error(r.msg);
						}
					}
				);
			}).catch(() => {

			});
		},
		handleSearch() {
			this.pageNo = 1;
			this.getDataList();
		},
		// 重置查询条件
		handleReset() {
			this.pageNo = 1;
			this.searchKey = '';
			this.deviceType = '';
			this.modelType = '';
			this.getDataList();
		},
		// 获取数据
		getDataList() {
			let that = this;
			that.$request.post(
				"sysDataDevicePageList",
				true,
				{
					pageNo: that.pageNo,
					pageSize: that.pageSize,
					deviceTypeId: that.deviceType,
					modelTypeId: that.modelType,
					searchKey: that.searchKey
				},
				function (r) {
					if (r.code == "0") {
						that.dataList = r.data.list;
						that.total = r.data.totalCount;
					} else {
						that.$message.error(r.msg);
					}
				}
			);
		},
		handleSizeChange() {
			this.getDataList();
		},
		handleCurrentChange() {
			this.getDataList();
		},
		// 导入
		handleImport() {
			let el = document.getElementById('uploadExcel')
            el.click();
		},
		// 上传
		uploadHandle(e) {
			let file = e.target.files[0];
            if(file){
				if(window.sessionStorage.getItem('userInfo').superAdminFlag == '1' && file.size/1024/1024/1024 > 1) {
					this.$message.error('导入文件不能大于1GB');
					return
				}
				// 2022-07-31 应客户要求改为200M
				if(window.sessionStorage.getItem('userInfo').superAdminFlag != '1' && file.size/1024/1024 > 200) {
					this.$message.error('导入文件不能大于200MB');
					return
				}
				if(file.name.includes('.xlsx')){
					let formdata = new FormData();
					formdata.append('file',file);
					let that = this;
					that.$request.initUploadExcel(
						formdata,
						'dataDeviceImport',
						true
					)
					.then((data) => {
						if(data.code == 0) {
							that.pageNo = 1;
							that.getDataList();
							if(data.data.length > 0) {
								that.visible = true;
								that.errorList = data.data;
							}else {
								that.$message.success('导入成功');
							}
						}
					})
				}else {
					this.$message.error('文件格式不正确');
				}
			}else{
				this.$message.error('请选择上传的文件');
			}
			this.$refs.uploadExcel.value = '';
		},
		// 导出
		handleExport() {
			let that = this
			that.$request.post(
				"dataDeviceExport",
				true,
				{
					pageNo: that.pageNo,
					pageSize: that.pageSize,
					deviceTypeId: that.deviceType,
					modelTypeId: that.modelType,
					searchKey: that.searchKey
				},
				function (res) {
					if (res.code == '0') {
						const linkNode = document.createElement('a');
						linkNode.style.display = 'none';
						linkNode.href = res.data;
						document.body.appendChild(linkNode);
						linkNode.click();  //模拟在按钮上的一次鼠标单击
						URL.revokeObjectURL(linkNode.href); // 释放URL 对象
						document.body.removeChild(linkNode);
					} else {
						that.$message.error(res.msg)
					}
				}
			)
		},
		// 模板下载
		handleTemplate() {
			const linkNode = document.createElement('a');
			linkNode.style.display = 'none';
			linkNode.href = '/static/template/MODELNAME-DATA.xlsx';
			linkNode.download = 'MODELNAME-DATA.xlsx';
			document.body.appendChild(linkNode);
			linkNode.click();  //模拟在按钮上的一次鼠标单击
			URL.revokeObjectURL(linkNode.href); // 释放URL 对象
			document.body.removeChild(linkNode);
		}
	}
}
</script>
<style lang="scss" scoped>
.data-content{
	.top-box{
		// height: 32px;
		margin-bottom: 20px;
	}
	.add-btn{
		display: inline-block;
		width: 88px;
		height: 32px;
		line-height: 32px;
		border: none;
		background-color: #117FFC;
		border-radius: 4px;
		color: #fff;
		font-size: 15px;
		text-align: center;
		cursor: pointer;
		margin-right: 10px;
	}
	.import-btn{
		display: inline-block;
		width: 88px;
		height: 32px;
		line-height: 32px;
		border: none;
		background-color: rgba(17, 127, 252, 0.2);
		border-radius: 4px;
		color: #117FFC;
		font-size: 15px;
		text-align: center;
		cursor: pointer;
		margin-right: 10px;
	}
	.template-btn{
		display: inline-block;
		width: 88px;
		height: 32px;
		line-height: 32px;
		border: none;
		background-color: rgba(17, 127, 252, 0.2);
		border-radius: 4px;
		color: #117FFC;
		font-size: 15px;
		text-align: center;
		cursor: pointer;
		margin-right: 10px;
	}
	.top-right-box{
		// float: right;
		text-align: right;
		margin-top: 15px;
		.label{
			font-size: 15px;
			margin-right: 5px;
		}
		.search-input,
		.search-select{
			width: 160px;
			margin: 0 10px;
		}
		.search-btn{
			width: 32px;
    		vertical-align: middle;
			cursor: pointer;
		}
		.refresh-btn{
			width: 32px;
			height: 32px;
			border: none;
			color: #117FFC;
			padding: 0;
			background-color: rgba(17, 127, 252, 0.2);
			font-weight: bold;
			font-size: 22px;
			vertical-align: bottom;
			margin-left: 5px;
		}
	}
	.btn-blue,
	.detail-btn{
		font-size: 12px;
		font-weight: bold;
		color: #1081FD;
		text-decoration: underline;
		border: none;
		cursor: pointer;
		background-color: #fff;
		margin-right: 10px;
	}
	.btn-red{
		font-size: 12px;
		font-weight: bold;
		color: #E9515E;
		text-decoration: underline;
		border: none;
		cursor: pointer;
		background-color: #fff;
		margin-right: 10px;
	}
}
.f-r{
	float: right;
}
.dialog-title{
	display: block;
	text-align: center;
	span{
		color: #222;
		font-weight: 600;
		font-size: 20px;
	}
	img{
		width: 28px;
		float: right;
		cursor: pointer;
	}
}
.error-list{
	height: 50vh;
    box-sizing: border-box;
    padding: 20px;
    overflow: auto;
	li{
		list-style: none;
	}
}
</style>

